import React from "react";
import AnswerCheck from "../layouts/AnswerCheck";
import TrueFalse from "../layouts/TrueFalse";

const Answers = ({questions}) => {
  return (
    <>
    {questions.map((item)=>(
      <div key={item.id} className="col-12 mb-6 mb-lg-0 my-3">
  <div className="card">
    <div className="">
      <table className="table table-sm  table-border-top-0">
        <thead>
          <tr>
            <th colSpan="2" className="py-2"  style={{fontSize:'0.9rem',letterSpacing:'0'}}>
            {item.name}
            </th>
          </tr>
        </thead>
        <tbody className="table-border-bottom-0">
          {item.options.map((opt)=>(
          <tr key={opt.id}>
            <td>
              <AnswerCheck text={opt.name} />
            </td>
            <td  style={{textAlign:'left'}}>
            <TrueFalse type={opt.checked_answer}/>
            </td>
          </tr>
        ))}

        </tbody>
      </table>
    </div>
  </div>
</div>
    ))}
    </>
  );
};

export default Answers;
