import React from "react";
import { useNavigate } from "react-router-dom";
 
const Lists = ({icon,id,name,status,type,voteType}) => {

  const navigate = useNavigate();
  const checkQusHandle = () => {
    
    if(type === 'form'){
      navigate(`/question/${id}`);
    }else if(type === 'answer'){
      navigate(`/answer/${id}`);
    }
  };

  return (
              <li
                 className="p-2"
                onClick={checkQusHandle}
                style={{marginBottom:'2px',borderBottom:'1px solid #00800014',cursor:'pointer'}}
              >
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="avatar me-2">
                      <span className={`badge bg-label-${status === '1' ? 'secondary' : 'primary'} rounded-pill p-1_5 me-3`}>
                      <i className={`fa-solid fa-${icon}`} style={{fontSize:'20px'}}></i>
                      </span>
                    </div>
                    <div className="me-2">
                      <h6 className="mb-0">{name}</h6>
                      <small>
                      {voteType === 1 ? 'ترشيح مجلس الإدارة' :'تصويت القرارات'}
                      </small>
                    </div>
                  </div>
                  <div className="ms-auto">
                    <div>
                      <span className={`badge bg-label-${type === 'form' ? 'secondary' : 'primary'}`}>{type === 'form' ? 'جديد' : 'مكتمل'}</span>
                    </div>
                  </div>
                </div>
              </li>

  );
};

export default Lists;
