import React, { useEffect } from 'react'
import axiosInstance from '../axiosInstance';

const TestPage = () => {
    useEffect(()=>{
         axiosInstance("/votes")
        .then((response)=>{
            console.log(response.data);
        })
        .catch((error)=>{
            console.log('Test Error',error);
        });
    },[]);

  return (
    <div>TestPage</div>
  )
}

export default TestPage