import { Routes, Route, useNavigate } from 'react-router-dom';
import Login from './components/Login';
import Otp from './components/Otp';
import { useEffect } from 'react';
import MainPage from './Pages/MainPage';
import QuestionsPage from './Pages/QuestionsPage';
import HomePage from './Pages/HomePage';
import AnswerPage from './Pages/AnswerPage';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Profile from './Pages/Profile';
import TestPage from './Pages/TestPage';


const App = () => {
  const navigate = useNavigate(); // استدعاء useNavigate

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    const idnationalChecked = localStorage.getItem('idnationalChecked');

    if(!token && window.location.pathname === '/'){
      localStorage.clear();
    }

    if (token) {
      // التوكن موجود، تحقق إذا كان المستخدم على صفحات /login أو /otp
      if (window.location.pathname === '/login' || window.location.pathname === '/otp') {
        navigate('/home');
      }
    } else {
      // إذا لم يكن هناك توكن، نفحص إذا كان الهاتف تم التحقق منه
      if (idnationalChecked) {
        navigate('/otp');
      } else {
        navigate('/login');
      }
    }
  }, [navigate]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/otp" element={<Otp />} />
      <Route path="/index" element={<MainPage />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/question/:id" element={<QuestionsPage />} />
      <Route path="/answer/:id" element={<AnswerPage />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/testpage" element={<TestPage />} />
    </Routes>
  );
};


export default App;



