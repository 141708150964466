import React from 'react'


const TrueFalse = ({type}) => {
  return (
    <>
            <span className="">
               {type?
                <i className="fa-regular fa-circle-check fa-lg text-success"></i>
                :
                <i className="fa-regular fa-circle-xmark fa-lg"></i>
                }
              </span>
    </>
  )
}

export default TrueFalse